import { useSelector, useDispatch } from 'react-redux';
import { SET_BASE_STATE } from 'store/actions';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';
import { ToastContainer } from 'react-toastify';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useEffect } from 'react';
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
import alertError from 'utils/alertError';
import alertSuccess from 'utils/alertSuccess';
import { loadActiveJob, updateConfirmInvoiceList } from 'store/thunk-actions';
global.EventSource =  NativeEventSource || EventSourcePolyfill;

// ==============================|| APP ||============================== //

const App = () => {

    const customization = useSelector((state) => state.customization);
    

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </StyledEngineProvider>
    );
};

export default App;
