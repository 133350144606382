import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  FormControl,
  FormHelperText,
} from '@mui/material';
import { TextField } from '@mui/material';
import api from 'api';
import { useEffect } from 'react';
import promiseToast from 'views/utilities/promiseToast';

const initValues = {
  currentPassword: '',
  newPassword: '',
  passwordConfirm: ''
}
function ChangePasswordDialog({ open, handleClose }) {

  useEffect(() => {
    resetForm();
  }, [open]);

  const { errors, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm, setValues, isSubmitting, touched, values, setSubmitting } = useFormik({
    initialValues: initValues,
    validationSchema: Yup.object().shape({
      currentPassword: Yup.string().required('Không được để trống'),
      newPassword: Yup.string().required('Không được để trống'),
      passwordConfirm: Yup.string().required('Không được để trống')
        .test('isConfirm', 'Xác nhận mật khẩu chưa đúng', (value, testContext) => {
          if (testContext.parent.newPassword != value) return false;
          return true;
        })
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const request = api.changePassword({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
        passwordConfirm: values.passwordConfirm
      });
      promiseToast(request, {
        success: {
          render() {
            return `Đổi mật khẩu thành công`
          }
        }
      });
      const { status } = await request;
      setSubmitting(false);
      if (status < 400) {
        resetForm();
        document.getElementById('btnClose').click();
      }
    },
    initialTouched: false
  });

  return (
    <>
      <Dialog
        open={open}
        fullWidth
      >
        <DialogTitle className='py-1'>
          <div className='d-flex align-items-center'>
            <div className='h5 d-inline flex-grow-1 mb-0'>Đổi mật khẩu</div>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              id="btnClose"
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <form noValidate onSubmit={handleSubmit} >            
            <FormControl fullWidth error={Boolean(touched.currentPassword && errors.currentPassword)}>
              <TextField
                required
                type="password"
                label="Mật khẩu cũ"
                value={values.currentPassword}
                name="currentPassword"
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {touched.currentPassword && errors.currentPassword && (
                <FormHelperText error className='mx-0'>
                  {errors.currentPassword}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth error={Boolean(touched.newPassword && errors.newPassword)} className='mt-3'>
              <TextField
                required
                type="password"
                label="Mật khẩu mới"
                value={values.newPassword}
                name="newPassword"
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {touched.newPassword && errors.newPassword && (
                <FormHelperText error className='mx-0'>
                  {errors.newPassword}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth error={Boolean(touched.passwordConfirm && errors.passwordConfirm)} className='mt-3'>
              <TextField
                required
                type="password"
                label="Xác nhận mật khẩu mới"
                value={values.passwordConfirm}
                name="passwordConfirm"
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {touched.passwordConfirm && errors.passwordConfirm && (
                <FormHelperText error className='mx-0'>
                  {errors.passwordConfirm}
                </FormHelperText>
              )}
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} variant='contained'>Lưu</Button>
        </DialogActions>
      </Dialog>
    </>

  )
}
export default ChangePasswordDialog