import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, Typography } from '@mui/material';

// project imports
import config from 'config';
import logo from 'assets/images/logo.png'

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = (/* { color } */) => (
    <ButtonBase disableRipple className='d-flex align-items-center'>
        <img src={logo} alt='logo' height={22}/>
        <Typography variant="h3" color={'primary'} sx={{paddingLeft: 1}}>
            SỔ THÔNG HÀNH
        </Typography>
    </ButtonBase>
);

export default LogoSection;
