import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import { useNavigate } from 'react-router-dom';

// assets

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >

                <Box component="span" sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                    <LogoSection color={theme.palette.secondary.dark} />
                </Box>
            </Box>

            <Button variant='contained' onClick={() => { navigate('/report') }} style={{ right: '15px', position: 'fixed' }}>Màn hình Quản trị viên</Button>

        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
