import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SET_BASE_STATE, SET_TOKEN, SET_USING_WORKSPACE } from 'store/actions';
import api from 'api';
import jwt_decode from "jwt-decode";

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
// import Customization from '../Customization';
import useMenuItems from 'menu-items';
import { drawerWidth } from 'store/constant';
import { SET_MENU } from 'store/actions';

// assets
import { IconChevronRight } from '@tabler/icons';
import { loadActiveJob, loadCompanies } from 'store/thunk-actions';
import Sidebar from 'layout/MainLayout/Sidebar';
import Header from './Header';
import AdminHeader from './Header';
import { useState } from 'react';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, issettingpath }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            // marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            // padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            // marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            // padding: '16px',
            // marginRight: '10px'
        }
    }),
    ...(issettingpath && {
        padding: '16px',
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            // marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            // marginLeft: '10px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const AdminLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const navigation = useMenuItems()
    const location = useLocation()
    const isSettingPath = location.pathname.startsWith('/setting')
    const [user, setUser] = useState({});

    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        let result;
        try {
            result = jwt_decode(token);
            const currentTime = Date.now() / 1000;
            if (result.exp < currentTime) {
                localStorage.removeItem('token');
                navigate('/login');
                return;
            }
            dispatch({
                type: SET_TOKEN,
                token
            });
            setUser(result);
        } catch {

        }
        if (!result?.username) {
            navigate('/login');
            return;
        }
        // (async () => {
        //     // const {data: user} = await api.getUser(result.userId)
        //     // if(!user){
        //     //     navigate('/login')
        //     //     return
        //     // }
        //     // const orgCode = user.orgCode
        //     // dispatch({
        //     //     type: SET_BASE_STATE,
        //     //     user,
        //     //     usingWorkspace: orgCode
        //     // })
        //     // dispatch(loadCompanies(orgCode))
        //     //dispatch(loadActiveJob())
        // })()
    }, [])

    useEffect(() => {
        if (isSettingPath) {
            dispatch({ type: SET_MENU, opened: !matchDownMd });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd, isSettingPath]);
    useEffect(() => {
        dispatch({ type: SET_MENU, opened: isSettingPath });
    }, [isSettingPath])

    // if(!curUser){
    //     return <></>
    // }
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >


                <Toolbar sx={{
                    padding: '3px 16px 3px 16px !important',
                }}>
                    <AdminHeader handleLeftDrawerToggle={handleLeftDrawerToggle} user={user} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened} issettingpath={isSettingPath ? 'true' : undefined}>
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
            </Main>
        </Box>
    );
};

export default AdminLayout;
