// action - state management
import * as actionTypes from './actions';

export const initialState = {
    inprogressSyncJob: [],
    confirmedUploadInvoices: []
};


const eventReducer = (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.SET_INPROGRESS_SYNC_JOBS:
        return {
          ...state,
          inprogressSyncJob: action.inprogressSyncJob
        }
      case actionTypes.SET_CONFIRM_INVOICES:
        return {
          ...state,
          confirmedUploadInvoices: action.confirmedUploadInvoices
        }
      default:
        return state;
    }
};

export default eventReducer;
