import TurnedInNotOutlinedIcon from '@mui/icons-material/TurnedInNotOutlined';
import { useSelector } from 'react-redux';
import pages from './pages';
import utilities from './utilities';


// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [/* dashboard,*/ pages, utilities,/*  other */]
};
function useMenuItems(){
    const workSpaceList = useSelector((state) => {
        return state.workspace.workspaceList
    })
    if(workSpaceList?.length){
        const pages = {
            id: 'pages',
            title: 'Workspace',
            type: 'group',
            children: workSpaceList.map(ws =>{
                return {
                    id: ws,
                    title: ws,
                    type: 'item',
                    url: `/setting`,
                    icon: TurnedInNotOutlinedIcon,
                    target: false
                }
            })
        };
        return {items: [pages, utilities]}
    }
    return {items: [utilities]}
}

export default useMenuItems;
