import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Button, Tabs, Tab } from '@mui/material';

// project imports
import ProfileSection from './ProfileSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import TabSection from './TabSection';
import { useLocation } from 'react-router-dom';
import LogoSection from 'layout/MainLayout/LogoSection';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const AdminHeader = ({ handleLeftDrawerToggle, user }) => {
    const theme = useTheme();
    const location = useLocation()
    const isSettingPath = location.pathname.startsWith('/setting')

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                {isSettingPath && <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden', marginRight: '10px' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        //onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        {/* <IconMenu2 stroke={1.5} size="1.3rem" /> */}
                    </Avatar>
                </ButtonBase>}
                <Box component="span" sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                    <LogoSection color={theme.palette.secondary.dark} />
                </Box>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            {/* <Box sx={{ flexGrow: 1 }} /> */}
            <Box sx={{ flexGrow: 1 }}>
                <Box
                sx={{ maxWidth: { xs: 320, sm: 480, md: 600, lg: 900 } }}
                >
                    <TabSection user={user}/>
                </Box>

            </Box>
            <Box sx={{ flexGrow: 1 }} />

            <ProfileSection user={user} />
        </>
    );
};

AdminHeader.propTypes = {
    //handleLeftDrawerToggle: PropTypes.func
};

export default AdminHeader;
