import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';


const MainFormPage = Loadable(lazy(() => import('views/main-form')));


// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    errorElement: <>123</>,
    children: [
        {
            path: '/',
            element: <MainFormPage/>
        }
        
    ]
};

export default MainRoutes;
