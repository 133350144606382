// assets
import { IconKey } from '@tabler/icons';

// constant
const icons = {
    IconKey
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    title: 'Workspace',
    type: 'group',
    children: [
        {
            id: 'link-tax-account',
            title: 'Liên kết tài khoản thuế',
            type: 'item',
            url: '/',
            icon: icons.IconKey,
            target: false
        },
        {
            id: 'manage-company',
            title: 'Quản lý công ty',
            type: 'item',
            url: '/',
            icon: icons.IconKey,
            target: false
        },
        {
            id: 'manage-employee',
            title: 'Quản lý nhân viên',
            type: 'item',
            url: '/',
            icon: icons.IconKey,
            target: false
        }
    ]
};

export default pages;
