// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

export const SET_USER = '@workspace/SET_USER';
export const SET_USING_WORKSPACE = '@workspace/SET_USING_WORKSPACE';
export const SET_USING_COMPANY = '@workspace/SET_USING_COMPANY';
export const TOGGLE_CONNECT_MODAL = '@workspace/TOGGLE_CONNECT_MODAL';
export const SET_BASE_STATE = '@workspace/SET_BASE_STATE';
export const SET_TOKEN = '@workspace/SET_TOKEN';

export const SET_INPROGRESS_SYNC_JOBS = '@event/SET_INPROGRESS_SYNC_JOBS'
export const REMOVE_COMPLETED_JOB = '@event/REMOVE_COMPLETED_JOB'
export const SET_CONFIRM_INVOICES = '@event/SET_CONFIRM_INVOICES'