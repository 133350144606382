// action - state management
import * as actionTypes from './actions';

export const initialState = {
    workspaceList: [],
    user: undefined,
    usingWorkspace: undefined,
    usingCompany: undefined,
    isModalAddTAOpen: false,
    usingTaxAccountOfWs: [],
    token: undefined
};


const workspaceReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER:
            return {
                ...state,
                user: action.user,
            }
        case actionTypes.SET_BASE_STATE:
            localStorage.setItem('org', action.usingWorkspace)
            action.usingCompany && localStorage.setItem('taxNo', action.usingCompany.taxNo)
            return {
                ...state,
                usingCompany: action.usingCompany,
                usingTaxAccountOfWs: action.list,
                user: action.user || state.user,
                workspaceList: action.user ? [action.user.orgCode] : state.workspaceList,
            };
        case actionTypes.SET_USING_WORKSPACE:
            action.usingWorkspace && localStorage.setItem('org', action.usingCompany)
            return {
                ...state,
                usingWorkspace: action.usingWorkspace,
            };
        case actionTypes.SET_USING_COMPANY:
            localStorage.setItem('taxNo',action.usingCompany.taxNo)
            action.usingCompany && localStorage.setItem('org', action.usingCompany.orgId)
            return {
                ...state,
                usingCompany: action.usingCompany,
                usingWorkspace: action.usingCompany.orgId,
            };
        case actionTypes.TOGGLE_CONNECT_MODAL:
            return {
                ...state,
                isModalAddTAOpen: action.isOpen
            }
        case actionTypes.SET_TOKEN:
            return {
                ...state,
                token: action.token
            }
        default:
            return state;
    }
};

export default workspaceReducer;
