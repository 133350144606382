import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import workspaceReducer from './workspaceReducer';
import eventReducer from './eventReducer'

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    workspace: workspaceReducer,
    event: eventReducer
});

export default reducer;
