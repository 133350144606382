import AuthLayout from 'layout/AuthLayout';
import { lazy } from 'react';

import Loadable from 'ui-component/Loadable';

const Login = Loadable(lazy(() => import('views/auth/login')));

const AuthRoutes = {
    path: '/',
    element: <AuthLayout />,
    children: [
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '/',
            element: <Login />
        }
    ]
};

export default AuthRoutes;
