import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  FormControl,
  FormHelperText,
} from '@mui/material';
import { TextField } from '@mui/material';
import api from 'api';
import { useEffect } from 'react';
import promiseToast from 'views/utilities/promiseToast';

function UpdateProfileDialog({ profile, handleClose }) {

  useEffect(() => {
    if (profile) {
      const {
        username,
        fullName,
        email,
        phone
      } = profile
      const formValues = {
        ...initValues,
        username: username || '',
        fullName: fullName || '',
        email: email || '',
        phone: phone || ''
      }
      setValues(formValues)
    }
  }, [profile]);

  const initValues = {
    username: '',
    fullName: '',
    email: '',
    phone: ''
  }

  const { errors, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm, setValues, isSubmitting, touched, values, setSubmitting } = useFormik({
    initialValues: initValues,
    validationSchema: Yup.object().shape({
      username: Yup.string().required('Không được để trống'),
      fullName: Yup.string().required('Không được để trống')
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const request = api.updateProfile({
        fullName: values.fullName,
        email: values.email,
        phone: values.phone
      });
      promiseToast(request, {
        success: {
          render() {
            return `Cập nhật thông tin thành công`
          }
        }
      });
      const { status } = await request;
      setSubmitting(false);
      if (status < 400) {
        resetForm();
        document.getElementById('btnClose').click();
      }
    },
    initialTouched: false
  });

  return (
    <>
      <Dialog
        open={!!profile}
        fullWidth
      >
        <DialogTitle className='py-1'>
          <div className='d-flex align-items-center'>
            <div className='h5 d-inline flex-grow-1 mb-0'>Thông tin tài khoản</div>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              id="btnClose"
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <form noValidate onSubmit={handleSubmit} >            
            <FormControl fullWidth error={Boolean(touched.username && errors.username)}>
              <TextField
                required
                disabled
                label="Tài khoản"
                value={values.username}
                name="username"
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {touched.username && errors.username && (
                <FormHelperText error className='mx-0'>
                  {errors.username}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth error={Boolean(touched.fullName && errors.fullName)} className='mt-3'>
              <TextField
                required
                label="Họ tên"
                value={values.fullName}
                name="fullName"
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {touched.fullName && errors.fullName && (
                <FormHelperText error className='mx-0'>
                  {errors.fullName}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl fullWidth className='mt-3'>
              <TextField
                label="Email"
                value={values.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>
            <FormControl fullWidth className='mt-3'>
              <TextField
                label="Số điện thoại"
                value={values.phone}
                name="phone"
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} variant='contained'>Lưu</Button>
        </DialogActions>
      </Dialog>
    </>

  )
}
export default UpdateProfileDialog