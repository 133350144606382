const defaultMessage = {
  pending: 'Đang xử lý',
  success: 'Xử lý thành công',
  error: {
    render({data}){
      return data || 'Lỗi xảy ra'
    }
  },
}
import { toast } from 'react-toastify';
export default function promiseToast(promise, messages = defaultMessage){
  toast.promise(
    transformRequestPromise(promise),
    {...defaultMessage, ...messages}, {progress: false}
  )
}
async function transformRequestPromise(promise){
  const {isSuccess, errorMessage, data} = await promise
  if(!isSuccess || data?.error) return Promise.reject(errorMessage || data?.error || data?.message)
  return Promise.resolve(data?.message || data?.error)
}
