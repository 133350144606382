import axios from "axios";
import { store } from '../store'
const axiosApi = axios.create();

function listener() {
  const token = localStorage.getItem("token");
  axiosApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
store.subscribe(listener)

// content type
axiosApi.defaults.baseURL = process.env.REACT_APP_BASE_URL || 'http://localhost:5174';
//axiosApi.defaults.baseURL = '/';

// intercepting to capture request
axiosApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers = {
      ...config.headers,
      'Authorization': `Bearer ${token}` || '',
    };
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// intercepting to capture errors
axiosApi.interceptors.response.use(
  async (response) => {
    return Promise.resolve({
      data: response.data,
      status: response.status,
      isSuccess: true,
    });
  },
  async (error) => {
    return Promise.resolve({
      errorMessage: error?.response.data.message || error?.response.data.error,
      status: error?.response?.status,
      isSuccess: false,
    });
  }
);

export default {
  get(url, params) {
    let response;
    if (params && Object.keys(params).length !== 0) {
      const queryString = params
        ? Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&")
        : "";

      response = axiosApi.get(
        `${url}?${queryString}`,
        params
      );
    } else {
      response = axiosApi.get(`${url}`);
    }
    return response;
  },


  /**
   * post given data to url
   */
  post(url, data, config = {}) {
    const { headers, ...rest } = config
    return axiosApi.post(url, data, config, {
      headers: {
        ...axios.defaults.headers,
        "Content-Type": "application/json",
        ...(headers ? headers : {})
      },
      ...rest
    });
  },


  /**
   * Updates data
   */
  update(url, data) {
    return axiosApi.put(url, data, {
      headers: {
        ...axios.defaults.headers,
        "Content-Type": "application/json",
      },
    });
  },

  /**
   * Deletes data
   */
  delete(url, data) {
    return axiosApi.delete(url, { data }, {
      headers: {
        ...axios.defaults.headers,
        "Content-Type": "application/json",
      },
    });
  },


  /**
   * post given data to url with file
   */
  uploadFile(url, data, config = {}) {
    const { headers, ...rest } = config;

    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    return axiosApi.post(url, formData, config, {
      headers: {
        ...axios.defaults.headers,
        "Content-Type": "multipart/form-data",
        ...(headers ? headers : {})
      },
      ...rest
    });
  }
}
