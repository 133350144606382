import api from "api";
import { SET_BASE_STATE, SET_CONFIRM_INVOICES, SET_INPROGRESS_SYNC_JOBS } from "./actions";

export function loadCompanies(orgCode) {
  return async (dispatch, getState) => {
    const { workspace: { usingCompany } } = getState();
    const { data: taxes } = await api.getTaxAccountByOrgId(orgCode);
    const tax = taxes.find(elem => elem.taxNo === localStorage.getItem('taxNo')) || taxes[0];
    dispatch({
      type: SET_BASE_STATE,
      usingWorkspace: orgCode,
      usingCompany: usingCompany || tax,
      list: taxes || [],
    })
  };
};
export function loadActiveJob(eventData){
  return async (dispatch, getState) => {
    const { event: { inprogressSyncJob: loadedJobs }, workspace: {user} } = getState();
    if(!user?.username || (eventData && eventData.username && user.username !== eventData.username)){
      return
    }
    if(eventData){
      const eventJobIndex = loadedJobs.findIndex(item => item._id === eventData.jobId)
      if(eventJobIndex >= 0){
        let newEventJob = {...loadedJobs[eventJobIndex]}
        const {totalR, totalV, countR, countV} = eventData
        newEventJob.detail = {
          ...newEventJob.detail,
          totalR: totalR,
          totalV: totalV,
          countR: countR,
          countV: countV
        }
        const updatedJobs = [...loadedJobs]
        updatedJobs.splice(eventJobIndex, 1, newEventJob)
        dispatch({
          type: SET_INPROGRESS_SYNC_JOBS,
          inprogressSyncJob: updatedJobs
        })
        if(totalR === countR && totalV === countV){
          setTimeout(()=>{
            dispatch(removeCompletedJob(eventData.jobId))
          }, 5000)
        }
        return
      }
    }
    const { data: inprogressSyncJob } = await api.getActiveSyncInvoiceJob();
    if(inprogressSyncJob){
      dispatch({
        type: SET_INPROGRESS_SYNC_JOBS,
        inprogressSyncJob
      })
    }
  }
}

export function removeCompletedJob(jobId){
  return async (dispatch, getState) => {
    const { event: { inprogressSyncJob: loadedJobs } } = getState();
    const eventJobIndex = loadedJobs.findIndex(item => item._id === jobId)
    const updatedJobs = [...loadedJobs]
    updatedJobs.splice(eventJobIndex, 1)
    dispatch({
      type: SET_INPROGRESS_SYNC_JOBS,
      inprogressSyncJob: updatedJobs
    })
  };
}

export function updateConfirmInvoiceList(eventData){
  return async (dispatch, getState) => {
    const { event: { confirmedUploadInvoices }, workspace: {user} } = getState();
    if(eventData && user.username === eventData.username){
      dispatch({
        type: SET_CONFIRM_INVOICES,
        confirmedUploadInvoices: [...confirmedUploadInvoices, eventData]
      })
    } else if(!eventData){
      dispatch({
        type: SET_CONFIRM_INVOICES,
        confirmedUploadInvoices: []
      })
    }
  }
}