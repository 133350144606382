import AdminLayout from 'layout/AdminLayout';
import { lazy } from 'react';

import Loadable from 'ui-component/Loadable';

const Report = Loadable(lazy(() => import('views/admin/report')));
const User = Loadable(lazy(() => import('views/admin/user')));
const Province = Loadable(lazy(() => import('views/admin/province')));
const District = Loadable(lazy(() => import('views/admin/district')));
const SystemLog = Loadable(lazy(() => import('views/admin/system-log')));
const SystemConfig = Loadable(lazy(() => import('views/admin/system-config')));
const MainFormPage = Loadable(lazy(() => import('views/main-form')));

const AdminRoutes = {
    path: '/',
    element: <AdminLayout />,
    children: [
        {
            path: '/report',
            element: <Report />
        },
        {
            path: '/province',
            element: <Province />
        },
        {
            path: '/district',
            element: <District />
        },
        {
            path: '/user',
            element: <User />
        },
        {
            path: '/system-log',
            element: <SystemLog />
        },
        {
            path: '/system-config',
            element: <SystemConfig />
        },
        {
            path: '/home',
            element: <MainFormPage />
        }
    ]
};

export default AdminRoutes;